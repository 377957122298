import React, { useEffect, useState } from 'react';

export default function TestGame(props) {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        props.xo.register((message) => setMessages(oldMessages => [...oldMessages, message]));
    }, [props.xo]);

    return <div style={{display: 'flex', flexDirection: 'column', width: '400px', padding: '20px'}}>
        <label>Message:</label>
        <input id="message"/>
        <button 
            style={{maxWidth: '100px'}}
            onClick={() => props.xo.send(document.getElementById("message").value)}
        >
            Click to send message
        </button>
        { messages.map((message, i) => <pre key={i}>{ JSON.stringify(message) }</pre>) }
    </div>;
}