import React from 'react';
import './PrivacyPolicy.css';

export default function PrivacyPolicy() {
    return <div className="outer">
        <div className = "inner">
        <h1>Privacy Policy</h1>
        <p>
            In order to provide and improve the experience of games hosted on XO, this site collects and stores data.
        </p>
        <h2>Data collected:</h2>
        <p>
            The following data is collected and stored:
            <li> <b>Unique Identifier:</b> A random string this site generates to uniquely identify for your browser. </li>
            <li> <b>Usernames:</b> Any name entered by you for use in playing games hosted on XO. </li>
            <li> <b>Moves:</b> Any information entered into a game hosted on XO. </li>
            The data is not sold or provided to any third parties. 
            You have the right to request access to or deletion of this data (see below).
        </p>

        <h2>Use of Local Storage:</h2>
        <p>
            The following data is stored in local storage in your browser. This is similar to the use of "Cookies" on other websites.
            <li><b>Usernames and Room Codes:</b> This is for ease-of-use. It allows the site to auto-populate the username and room code fields when you return to visit.</li>
            <li><b>Unique Identifier:</b> This allows us distinguish between various users attempting to use the same username.</li>
        </p>

        <h2>Your Rights:</h2>
        <p>
            You may request to view or delete any data pertaining to a specific username or unique identifier (as defined above). 
            To do so, please contact <a href="mailto:privacy@playxo.games">privacy@playxo.games</a>. 
        </p>
        <br/>
        <a href="/">Return to main site</a>
    </div>
    </div>
}