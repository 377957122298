import React from 'react';
import Client from './Client/Client';
import PrivacyPolicy from './Client/PrivacyPolicy';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
        <Switch>
          <Route exact path="/privacy">
            <PrivacyPolicy />
          </Route>
          <Route>
            <Client />
          </Route>
        </Switch>
    </BrowserRouter>
  );
}

export default App;
