import React, { useEffect, useState } from 'react';

export default function Armageddon(props) {
    const [gameModel, setGameModel] = useState(null);

    useEffect(() => {
        props.xo.register((data) => {
            const jsonMessage = JSON.parse(data.message);
            if (!jsonMessage.player || jsonMessage.player === props.name) {
                setGameModel(jsonMessage.gameModel);
            }
        })
    }, [props.xo, props.name]);

    return <div>
        { gameModel && gameModel.gamePhase > 0
            ? <div>
                { gameModel.gamePhase === 1 && isPicker(gameModel, props.name)
                    ? <SelectCardButtonList gameModel={gameModel} {...props} />
                    : null
                }
                { gameModel.gamePhase === 2 && actionPending(gameModel, props.name)
                    ? <SelectAnteButtonList gameModel={gameModel} {...props} />
                    : null
                }
                <Cards gameModel={gameModel} {...props}/>
            </div>
            : "Waiting for host to start the game"
        }
    </div>
}

function actionPending(gameModel, name) {
    const player = getPlayer(gameModel, name);
    return player && player.actionPending;
}

function isPicker(gameModel, name) {
    return gameModel.players[gameModel.turn % gameModel.players.length].name === name;
}

function getPlayer(gameModel, name) {
    if (gameModel && gameModel.players) {
        const playerList = gameModel.players.filter(player => player.name === name);
        if (playerList.length === 1) {
            return playerList[0];
        }
    }

    return null;
}

function Cards(props) {
    const player = getPlayer(props.gameModel, props.name);
    if (player && player.hand) {
        return <div style={{display: 'flex', position: 'absolute', right: '0px', bottom: '0px'}}>
            { player.hand.map((card, i) => <Card key={i} card={card}/>) }
        </div>
    } else {
        return null;
    }
}

function Card(props) {
    return <div style = {{
        color: props.card.suit % 2 === 1 ? 'black' : 'red',
        width: '80px',
        borderTop: '2px black solid',
        borderLeft: '2px black solid',
        borderRadius: '15px 0px 0px 0px',
        marginRight: '-15px',
        height: '50px',
        fontSize: '24px',
        padding: '5px',
        backgroundColor: 'white',
        fontFamily: 'arial',
        fontWeight: 'bold'
    }}>
        { getCardText(props.card) }
    </div>
}

function getCardText(card) {
    return ["?", "A", "2", "3", "4", "5", "6", "7", "8", "9", "10", "J", "Q", "K"][card.rank] + " " + 
        [ "♥", "♠", "♦", "♣"][card.suit];
}

function ButtonList(props) {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    }}> 
        { props.buttons.map((button, i) => button ? <Button key={i} {...button}/> : null) }
    </div>;
}

function Button(props) {
    return <div
        style={{
            width: '200px',
            backgroundColor: '#296bff',
            color: 'white',
            margin: '10px',
            padding: '10px',
            fontFamily: 'arial',
            borderRadius: '10px',
            cursor: 'pointer',
        }}
        onClick={props.onClick}
    >
        <div style={{fontWeight: 'bold', marginBottom: '5px'}}> {props.title} </div>
        <div> {props.body} </div>
    </div>
}

function SelectCardButtonList(props) {
    const player = getPlayer(props.gameModel, props.name);
    if (player && player.hand) {
        return <ButtonList buttons={
            player.hand.map((card, i) => getSelectCardButtonInfo(card, props.xo, i))
        }/>
    } else {
        return null;
    }
}

function getSelectCardButtonInfo(card, xo, index) {
    const onClick = () => {xo.send(index.toString())};
    const prefix = getCardText(card) + " ";
    switch(card.rank) {
        case 3:
            return {
                title: prefix + "Three is Lucky",
                body: "Random person drinks.",
                onClick
            };
        case 4:
            return {
                title: prefix + "Four is Pour",
                body: "Everyone takes a shot and the worst shot drinks.",
                onClick
            };
        case 5:
            return {
                title: prefix + "Five is Contrive",
                body: "Come up with your own game.",
                onClick
            };
        case 6:
            return {
                title: prefix + "Six is Pick",
                body: "Pick who drinks.",
                onClick
            };

        case 7:
            return {
                title: prefix + "Thumbs on Face",
                body: "Last person with their thumb on their face loses.",
                onClick
            };
        case 8:
            return {
                title: prefix + "Eight is Flip Rate",
                body: "Play flip-cup.",
                onClick
            };

        case 9:
            return {
                title: prefix + "Poker",
                body: "Worst poker hand loses.",
                onClick
            };
        case 10:
            return {
                title: prefix + "Categories",
                body: "Rapidly come up with elements of a category.",
                onClick
            };

        case 11:
            return {
                title: prefix + "Rock Paper Scissors",
                body: "Play a Rock-Paper-Scissors tournament.",
                onClick
            };
        case 12:
            return {
                title: prefix + "Bullshit",
                body: "Have others call bullshit on facts about your cards.",
                onClick
            };

        case 13:
            return {
                title: prefix + "Tallest Drink",
                body: "Person with the tallest drink loses.",
                onClick
            };
        case 2:
        case 1:
        default:
            return null;
    }
}

function SelectAnteButtonList(props) {
    const player = getPlayer(props.gameModel, props.name);
    if (player && player.hand) {
        const buttons = [];
        if (player.hand.filter(card => card.rank === 2).length > 0) {
            buttons.push({
                title: "Skip",
                body: "Spend an two to skip this round.",
                onClick: () => {props.xo.send("skip")}
            });
        }
        if (player.hand.filter(card => card.rank === 1).length > 0) {
            buttons.push({
                title: "Double",
                body: "Spend a ace to double the drinking length this round.",
                onClick: () => {props.xo.send("double")}
            });

        }
        buttons.push({
            title: "Pass",
            body: "Don't play any Aces or Twos.",
            onClick: () => {props.xo.send("neither")}
        })
        return <ButtonList buttons={buttons}/>
    } else {
        return null;
    }
}