import React, { useEffect, useState } from 'react';
import TestGame from '../Games/TestGame';
import TicTacToe from '../Games/TicTacToe/TicTacToe';
import Armageddon from '../Games/Armageddon/Armageddon';
import './Client.css';
import XOBGLeft from './xobg_left.svg';
import XOBGRight from './xobg_right.svg';
import XOTitle from './xo_title.svg';
import XOX from './xo_x.svg';
import TicTacToeFavicon from './favicons/tictactoe.ico';
import ClientFavicon from './favicons/client.ico';

const URL = "wss://gco990fkid.execute-api.us-east-1.amazonaws.com/production";

function getMagic() {
  let codeString = "";
  
  for (var k = -12; k < 8; k++) {
      if (k % 8 !== 2) {
          for (var j = -9; j < 13; j++) {
              if (k % 3 === 0 && (j * k) % 3 === 0) {
                  codeString += ("" + Math.floor(10 * Math.cos(k) * 10 * Math.sin(j)));
              }
          }
      }
  }
  
  return codeString;
}

function code(str, isEn) {
    var codeString = getMagic();
    var decoded = "";

    for (var i = 0; i < str.length; i++) {
        decoded += String.fromCharCode(((isEn ? 1 : -1) * codeString.charCodeAt(i % codeString.length) + str.charCodeAt(i) + 256) % 256);
    }

    return decoded;
}

function encode(str) {
    return encodeURIComponent(code(str, true));
}

function decode(str) {
    return code(decodeURIComponent(str), false);
}

function sendAWS(ws, message) {
  ws.send(JSON.stringify({action: "sendmessage", data: encode(message)}));
}

class XOClient {
  mq = [];
  callback = (m) => { this.mq.push(m) };
  
  ws;
  name;
  roomID;
  
  constructor(ws, name, roomID) {
    this.ws = ws;
    this.name = name;
    this.roomID = roomID;
  }

  register(callback) {
    this.callback = callback;
    this.mq.forEach(m => callback(m));
  }

  send(message) {
    sendAWS(this.ws, JSON.stringify({
      type: "MESSAGE", 
      body: { name: this.name, roomID: this.roomID, rejoinKey: localStorage.getItem("rejoin_key"), message }
    }));
  }
}

function Client() {
  const [ws, setWs] = useState(null);
  const [isLogin, setIsLogin] = useState(true);
  const [loginFailureReason, setLoginFailureReason] = useState(null);
  const [gameCode, setGameCode] = useState('');
  const [gameVersion, setGameVersion] = useState(-1);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  document.title = getPageTitle(gameCode);

  useEffect(() => {
    document.getElementById("name").value = localStorage.getItem("name");
    document.getElementById("roomID").value = localStorage.getItem("roomID");
  }, [])

  useEffect(() => {
    let icon = ClientFavicon;
    switch (gameCode) {
      case 'tictactoe':
        icon = TicTacToeFavicon;
        break;
      default:
        break;
    }
    document.getElementById("favicon").href = icon;
  }, [gameCode])

  return (
    isLogin
      ? <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', height: 'max(100vh, 600px)', overflowY: 'hidden'}}>
          <div style={{position: 'absolute', left: '0px', top: '50px'}}>
            <img style={{width: 'min(400px, 30vw)', zIndex: '-1', transformOrigin: 'top left'}} className="xoclient-zoombox" alt="" src={XOBGLeft} />
          </div>
          <div style={{position: 'absolute', right: '0px', top: '50px'}}>
            <img style={{width: 'min(400px, 30vw)', zIndex: '-1', transformOrigin: 'top right'}} className="xoclient-zoombox" alt="" src={XOBGRight} />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', transformOrigin: 'top center'}} className="xoclient-zoombox">
            <img height="50" alt="xo" src={XOTitle} style={{margin: '30px'}}/>
            <div style={{ maxWidth: '40vw' }} >
              <InputRow
                label="Room Code"
                id="roomID" 
                errorText={getRoomCodeErrorText(loginFailureReason)}
                maxLength={4}
              />
              <InputRow
                label="Name"
                id="name"
                errorText={getNameErrorText(loginFailureReason)}
                maxLength={12}
                canActivateButton
              />
            </div>
            <JoinButton {...{ws, setWs, setIsLogin, loginFailureReason, setLoginFailureReason, setGameCode, setGameVersion, setName, loading, setLoading}}/>
            <div style={{marginTop: '20px', fontSize: '0.5em', maxWidth: '260px', textAlign: 'center'}}>
            <Text>
              By clicking the button above, you acknowledge the site's <Link href="/privacy">privacy policy</Link> and agree to its usage of local storage
            </Text>
          </div>
          </div>
          <div style={{
            position: 'absolute',
            bottom: '10px',
            marginTop: '60px',
            fontSize: '0.75em'
          }}>
            <Text>
              © Andrew Murray 2020 | <Link href="http://andrfw.com">andrfw.com</Link>
            </Text>
          </div>
        </div>
    : <GameComponent gameCode={gameCode} gameVersion={gameVersion} xo={ws} name={name}/>
  );
}



function Text(props) {
  return <span 
  className="xoclient-usefont"
>
  {props.children}
</span>
}

function Link(props) {
  return <a href={props.href} style={{color: "#6E00FF"}}>
    <Text>
      {props.children}
    </Text>
  </a>
}

function getCleanedName(name) {
  const acceptableCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('');
  return name.split("")
    .filter((char) => acceptableCharacters.indexOf(char) !== -1)
    .reduce((a, b) => a + b, '')
    .toUpperCase();
}

function setupWebsocket(name, roomID, setWs, setIsLogin, setLoginFailureReason, setGameCode, setGameVersion, setName, setLoading) {
  name = getCleanedName(name);

  if (name === '') {
    setLoginFailureReason('EMPTY_NAME');
    return;
  }

  setLoading(true);

  const newWs = new XOClient(new WebSocket(URL), name, roomID);

  newWs.ws.onmessage = (message) => {
    setLoading(false);
    const jsonData = JSON.parse(decode(message.data));

    switch (jsonData.type) {
      case "JOIN_ACK":
        window.localStorage.setItem("rejoin_key", jsonData.body.rejoinKey);
        window.localStorage.setItem("name", name);
        window.localStorage.setItem("roomID", roomID);
        setIsLogin(false);
        setGameCode(jsonData.body.gameCode);
        setGameVersion(jsonData.body.gameVersion);
        setName(name);
        break;
      case "ROOM_DOES_NOT_EXIST":
      case "NAME_TAKEN":
      case "ROOM_FULL":
      case "ROOM_CLOSED":
        setLoginFailureReason(jsonData.type);
        break;
      case "MESSAGE_RELAY":
        newWs.callback(jsonData.body);
        break;
      case "REMOVE_NOTICE":
        window.location.reload();
        break;
      default:
        break;
    }
  };

  newWs.ws.onopen = () => {
    setLoginFailureReason(null);
    sendJoin(newWs.ws, name, roomID);
  }
  
  newWs.ws.onclose = () => {
    setupWebsocket(name, roomID, setWs, setIsLogin, setLoginFailureReason, setGameCode, setGameVersion, setName, setLoading);
  }

  setWs(newWs);
}

function sendJoin(ws, name, roomID) {
  const rejoinKey = localStorage.getItem("rejoin_key");

  sendAWS(ws, JSON.stringify({
    type: "JOIN_ROOM", 
    body: {
      name,
      roomID,
      rejoinKey: rejoinKey ? rejoinKey : undefined
    }
  }));
}

function InputRow(props) {
  return (
        <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
          <label className="xoclient-usefont">{ props.label }</label>
          <input
            style={{ fontSize: '16px' }}
            id={props.id}
            autoComplete="off"
            className="xoclient-usefont"
            maxLength={props.maxLength}
            onKeyDown={(e) => {
              if (props.canActivateButton && e.key === 'Enter') {
                document.getElementById("xoclient_join_button").click();
              }
            }}
          />
          <div style={{ color: 'red', fontSize: '12px', minHeight: '20px'}} className="xoclient-usefont">
            { props.errorText }
          </div>
        </div>
  );
}

function JoinButton(props) {
  const onClick = () => {
    if (!props.loading) {
      setupWebsocket(
        document.getElementById("name").value,
        Number(document.getElementById("roomID").value) || 0,
        props.setWs, props.setIsLogin, props.setLoginFailureReason, props.setGameCode, props.setGameVersion, props.setName, props.setLoading
      );
    }
  };

  return <div
    style={{
      width: '120px',
      minHeight: '40px',
      marginTop: '20px',
      color: 'white',
      backgroundColor: "#6E00FF",
      borderRadius: '10px',
      cursor: props.loading ? 'default' : 'pointer',
      border: '0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: "#4d00b3"
      }
    }}
    id="xoclient_join_button"
    tabIndex={0}
    onClick={onClick}
    onKeyDown={(e) => { if (e.key === 'Enter') { onClick(); }}}
  >
      { props.loading
        ? <LoadingAnimation />
        : <div className="xoclient-usefont"> Let's Go! </div>
      }
  </div>;
}

function LoadingAnimation() {
  return <div style={{position: 'relative', width: '40px', height: '10px'}}>
    <img className='xoclient-loadingx1' src={XOX} width={10} height={10} alt=''/>
    <img className='xoclient-loadingx2' src={XOX} width={10} height={10} alt=''/>
  </div>
}

function GameComponent(props) {
  switch (props.gameCode) {
    case "tictactoe":
      return <TicTacToe {...props} />;
    case "armageddon":
      return <Armageddon {... props} />;
    case "testgame":
    default:
      return <TestGame {...props} />;
  }
}

function getPageTitle(gameCode) {
  switch (gameCode) {
    case "tictactoe":
      return "Tic Tac Together";
    case "armageddon":
        return "Armageddon";
    default:
      return "XO";
  }
}

function getRoomCodeErrorText(error) {
  switch (error) {
    case 'ROOM_DOES_NOT_EXIST':
      return 'Invalid Code';
    case 'ROOM_CLOSED':
      return 'Room Closed';
    case 'ROOM_FULL':
      return 'Room Full';
    default:
      return '';
  }
}

function getNameErrorText(error) {
  switch (error) {
    case 'NAME_TAKEN':
      return 'Name Taken';
    case 'EMPTY_NAME':
      return 'No Name';
    default:
      return '';
  }
}

export default Client;
