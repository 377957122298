import React, { useState, useEffect } from 'react';
import GridImg from './grid.png';
import BoardImg from './board.png';
import WhiteBoardImg from './board_white.png';
import XGuy from './x_guy.svg';
import OGuy from './o_guy.svg';
import XTeamIndicatorConfirmed from './team_indicator_x_confirmed.svg';
import OTeamIndicatorConfirmed from './team_indicator_o_confirmed.svg';
import XTeamIndicator from './team_indicator_x.svg';
import OTeamIndicator from './team_indicator_o.svg';
import StarGuy from './star_guy.svg';
import XWaitingScreen from './x_waiting_screen.svg';
import OWaitingScreen from './o_waiting_screen.svg';
import NotebookImg from './notebook.png';
import DarkNotebookImg from './notebook_dark.png';
import { TiArrowBack, TiWeatherNight, TiWeatherSunny } from 'react-icons/ti';
import './TicTacToe.css';

const RECURSIVE_BOARD_SCALE = 4;

function TicTacToe(props) {
  const [gameState, setGameState] = useState('LOBBY');
  const [board, setBoard] = useState({});
  const [currentBoard, setCurrentBoard] = useState([]);
  const [winner, setWinner] = useState(true);
  const [team, setTeam] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [friendlyMoves, setFriendlyMoves] = useState([]);
  const [isAwaitingSwapAck, setIsAwaitingSwapAck] = useState(false);
  const [gameSettings, setGameSettings] = useState({canSwapTeams: true, canSeeOtherMoves: true});
  const [movesLeft, setMovesLeft] = useState(1);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const appendBoard = (val) => {
    setCurrentBoard((currentCurrentBoard) => [...currentCurrentBoard, val]);
  }

  const popBoard = () => {
    setCurrentBoard((currentCurrentBoard) => currentCurrentBoard.slice(0, currentCurrentBoard.length - 1));
  }

  const submitMove = (moveString) => {
    if (props.gameVersion < 2) {
      props.xo.send(moveString);
    } else {
      props.xo.send(JSON.stringify({type: "SUBMIT_MOVE", move: moveString, movesLeft, team}));
    }

    if (movesLeft === 1) {
      setGameState("WAITING");
    }
    setMovesLeft((curr) => {
      if (curr - 1 === 0) {
        setCurrentBoard([]);
      }
      return curr - 1
    });
    setHoverIndex(null);
    setFriendlyMoves((old) => getNewFriendlyMoves(old, moveString, props.name, movesLeft, team, true));
  }

  const sendHoverMove = (moveString) => {
    if (props.gameVersion >= 2) {
      props.xo.send(JSON.stringify({type: "HOVER_MOVE", move: moveString, movesLeft, team}));
    }
  }

  const sendSwapTeam = () => {
    if (props.gameVersion >= 2) {
      setIsAwaitingSwapAck(true);
      props.xo.send(JSON.stringify({ type: "SWAP_TEAM" }));
    }
  }

  useEffect(() => {
    props.xo.register((data) => {
      const jsonMessage = JSON.parse(data.message);
      if (data.name === 'host') {
        if (jsonMessage.game) {
          setBoard(jsonMessage.game);
        }
        if (jsonMessage.winner) {
          setWinner(jsonMessage.winner);
        }
        if (jsonMessage.movesLeft && jsonMessage.movesLeft[props.name]) {
          setMovesLeft(jsonMessage.movesLeft[props.name]);
        }

        switch (jsonMessage.type) {
          case "SWAP_TEAM_ACK":
            if (jsonMessage.name === props.name) {
              setIsAwaitingSwapAck(false);
              if (jsonMessage.success) {
                setTeam((curr) => curr === 'X' ? 'O' : 'X');
              }
            }
            break;
          case "ENTER_LOBBY":
            setCurrentBoard([]);
            setFriendlyMoves([]);
            setIsAwaitingSwapAck(false);
            setGameState('LOBBY');
            break;
          case "GAME_ENDED":
            setCurrentBoard([]);
            setGameState('RESULTS');
            setFriendlyMoves([]);
            break;
          case "ROUND_ENDED":
              setCurrentBoard([]);
              setGameState((curr) => curr === 'WAITING' ? 'ROUND_END_SUCCESS' : 'ROUND_END_TIMEOUT');
              break;
          case "GAME_ENDED_PREMATURE":
            setCurrentBoard([]);
            setFriendlyMoves([]);
            setGameState('HOST_ERROR');
            break;
          case "NEXT_TURN":
            setFriendlyMoves([]);
            setGameState('TURN');
            setHoverIndex(null);
            setCurrentBoard([]);
            if (!jsonMessage.movesLeft) {
              setMovesLeft(1);
            }
            break;
          case "SET_SETTINGS":
            if (jsonMessage.gameSettings) {
              setGameSettings(jsonMessage.gameSettings);
            }
            break;
          case "NEW_PLAYER":
            if (jsonMessage.player === props.name)  {
              if (jsonMessage.team) {
                setTeam(jsonMessage.team);
              }
              if (jsonMessage.winner) {
                setGameState('RESULTS');
              } else if (jsonMessage.game) {
                if (jsonMessage.awaitingMove) {
                  setGameState('TURN');
                } else {
                  setGameState('WAITING'); 
                }
              } else {
                setGameState('LOBBY');
                setIsAwaitingSwapAck(false);
              }
              if (jsonMessage.gameSettings) {
                setGameSettings(jsonMessage.gameSettings);
              }
            }
            break;
          default:
            break;
        }
      } else {
        switch (jsonMessage.type) {
          case "SUBMIT_MOVE":
          case "HOVER_MOVE":
            setFriendlyMoves((old) => getNewFriendlyMoves(old, jsonMessage.move, data.name, jsonMessage.movesLeft, jsonMessage.team, jsonMessage.type === "SUBMIT_MOVE"));
            break;
          default:
            break;
        }
      }
    });
  }, [props.xo, props.name]);
  
  let title = "Loading...";
  let subtitle = null;
  let boardIsVisible = false;
  let boardIsDisabled = true;
  let controlButtonsVisible = false;
  let content = null;

  switch (gameState) {
    case "LOBBY":
      if (team) {
        title = `${team} Team`;
        subtitle = "waiting for the host to start the game";
        content = <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <div style={{position: 'relative', margin: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}> 
            <img width="250px" height="250px" src={team === "X" ? XGuy : OGuy} alt={team} />
            <div style={{
              color: isDarkMode ? 'white' : 'black',
              textShadow: isDarkMode ? '0px 0px 8px rgba(0,0,0,0.8)' : undefined,
              fontSize: '4em', textAlign: 'center', position: 'absolute', bottom: '-0.25em'}} className="tictactoe-usefont">{props.name}</div>
          </div>
          { props.gameVersion >= 2 && gameSettings.canSwapTeams ?
            <Button onClick={sendSwapTeam} disabled={isAwaitingSwapAck} isDarkMode={isDarkMode}>
              Swap Team
            </Button>
            : null 
          }
        </div>;
      }
      break;
    case "TURN":
      title = "Select Moves";
      subtitle = `${movesLeft} Left`;
      boardIsVisible = true;
      boardIsDisabled = false;
      controlButtonsVisible = true;
      break;
    case "ROUND_END_SUCCESS":
    case "ROUND_END_TIMEOUT":
    case "WAITING":
      title = gameState === "ROUND_END_TIMEOUT" ? "Time's Up!" : "Move Sent!";
      subtitle = gameState === "WAITING" ? "waiting for other players..." : "watch the host's screen...";
      content = <img width="300px" src={team === 'X' ? XWaitingScreen : OWaitingScreen} alt='' />;
      break;
    case "RESULTS":
      if (winner === 'X' || winner === 'O') {
        title = `${winner} Team Wins`;
      } else if (winner === 'STAR') {
        title = 'Both Win';
      } else {
        title = 'Draw Game';
      }
      boardIsVisible = true;
      boardIsDisabled = true;
      break;
    case "HOST_ERROR":
      title = "Game Ended"
      subtitle = "The host has closed this room"
      boardIsVisible = false;
      boardIsDisabled = true;
      content = <a style={{textDecoration: 'none'}} href={document.URL}><Button isDarkMode={isDarkMode}>Return to XO</Button></a>;
      break;
    default:
      break;
  }

  return <div style={{ height: '100%' }}>
    <GridBackground currentBoard={currentBoard} isDarkMode={isDarkMode}/>
    <Banner 
      {...{submitMove, popBoard, boardIsDisabled, currentBoard, hoverIndex, setHoverIndex, title, subtitle, isDarkMode}}
    />
    <DarkModeButton {...{isDarkMode, setIsDarkMode}}/>
    <div style={{
      width: '100vw',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden'
    }} className='tictactoe-container'>
      <div style={{ transform: `scale(${Math.pow(RECURSIVE_BOARD_SCALE, currentBoard.length)}) translate(${getOffset(currentBoard, true)}px, ${getOffset(currentBoard, false)}px)`,
}}>
        { boardIsVisible
          ? <Board 
            board={board} 
            submitMove={submitMove} 
            currentBoard={currentBoard} 
            appendBoard={appendBoard}
            popBoard={popBoard}
            disabled={boardIsDisabled}
            hoverIndex={hoverIndex}
            setHoverIndex={setHoverIndex}
            friendlyMoves={friendlyMoves}
            setFriendlyMoves={setFriendlyMoves}
            sendHoverMove={sendHoverMove}
            gameSettings={gameSettings}
            team={team}
            name={props.name}
            isDarkMode={isDarkMode}/>
          : <div style={{position: 'relative', margin: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}> 
            { content }
          </div>
        }
      </div>
      { controlButtonsVisible && <ControlButtons {...{submitMove, popBoard, currentBoard, hoverIndex, setHoverIndex, movesLeftString: subtitle, isDarkMode}} /> }
    </div>
  </div> 
}

function getNewFriendlyMoves(old, move, name, movesLeft, team, locked) {
  const key = name + movesLeft;
  return [...old.filter(e => e.key !== key), { move, locked, key, team }];
}

function Banner(props) {
  return <div style={{position: 'absolute', top: '0', width: '100%', display: 'flex', justifyContent: 'center'}}>
    <div style={{
      display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', 
      zIndex: 1, 
      backgroundImage: `url(${props.isDarkMode ? DarkNotebookImg : NotebookImg})`, backgroundPosition: 'bottom left', backgroundSize: '100%',
      width: '600px', maxWidth: '90vw',
      paddingTop: '10px', paddingBottom: '10px'
    }}>
      <div style={{color: props.isDarkMode ? 'rgba(16, 24, 16, 1)' : 'rgb(65 65 65)', fontSize: '2em', textAlign: 'center'}} className="tictactoe-usefont">
        {props.title}
      </div>
      <div style={{color: props.isDarkMode ? 'rgba(16, 24, 16, 1)' : 'rgb(65 65 65)', fontSize: '1em', textAlign: 'center', minHeight: '30px', maxWidth: 'calc(100% - 100px)', display: 'flex', alignItems: 'center', marginBottom: '10px', lineHeight: 0.8 }} className="tictactoe-usefont">
        {props.subtitle}
      </div>
    </div>
  </div>
}

function DarkModeButton(props) {
  return <div style={{position: 'absolute', top: '-0px', right: '0px', zIndex: 2}}>
    <Button
      style={{
        borderRadius: '0 0 0 10px',
        padding: '0.4em',
        margin: '0',
        height: undefined
      }}
      onClick={() => props.setIsDarkMode(!props.isDarkMode)}
      isDarkMode={props.isDarkMode}
    > {props.isDarkMode ? <TiWeatherSunny /> : <TiWeatherNight />} </Button>
  </div>
}

function ControlButtons(props) {
  return <div style={{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    bottom: '0px',
  }}>
    <div style={{
      display: 'flex', 
      alignItems: 'center',
      width: '100%',
      maxWidth: '500px'
    }}>
    <div style={{width: '33%'}}>
      <Button
        onClick={ () => {
          props.popBoard();
          props.setHoverIndex(null);
        }}
        isDarkMode={props.isDarkMode}
        disabled={props.currentBoard.length === 0}
      > 
        <TiArrowBack />
      </Button>
      </div>
      <div style={{width: '66%'}}>
      <Button
          onClick={() => {
            if (props.hoverIndex) {
              const moveString = getMoveString(props.currentBoard, props.hoverIndex.toString());
              props.submitMove(moveString);
            }
          }}
          isDarkMode={props.isDarkMode}
          disabled={!props.hoverIndex}
        >
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{marginBottom: '-0.5em'}}>Submit</div>
            <div style={{fontSize: '0.5em'}}>{props.movesLeftString}</div>
          </div>
        </Button>
        </div>
      </div>
    </div>
}

function getMoveString(currentBoard, cell) {
  return currentBoard.reduce((a, b) => a.toString() + b.toString(), '') + cell;

}

function GridBackground(props) {
  return <div style={{
    width: '100vw',
    height: '100%',
    overflow: 'hidden',
    position: 'absolute',
    top: '0px'
  }}>
    <div style={{
        width: '100%',
        height: '100%',
        backgroundImage: props.isDarkMode ? undefined : `url(${GridImg})`,
        backgroundColor: props.isDarkMode ? 'rgba(16, 24, 16, 1)' : undefined,
        transform: `scale(${Math.pow(RECURSIVE_BOARD_SCALE, props.currentBoard.length)}) translate(${getOffset(props.currentBoard, true)}px, ${getOffset(props.currentBoard, false)}px)`,
        backgroundSize: '21px',
        zIndex: -2,
      }}/>
    </div>;
}

function getOffset(currentBoard, isLeft) {
  return currentBoard.reduce(
    (a, b, index) => 
      a + (Math.pow(1/RECURSIVE_BOARD_SCALE, index) * getBaseOffset(b, isLeft)),
    0
  );
}

function getBaseOffset(index, isLeft) {
  if (isLeft) {
    switch (index) {
      case 3:
      case 6:
      case 9:
      return -97;

      case 2:
      case 5:
      case 8:
      return 0;

      case 1:
      case 4:
      case 7:
      default:
      return 97;
    }
  } else {
    switch (index) {
      case 1:
      case 2:
      case 3:
      return -97;

      case 4:
      case 5:
      case 6:
      return 0;

      case 7:
      case 8:
      case 9:
      default:
      return 97;
    }
  }
}

function Board(props) {
  return <div style={{ position: 'relative' }}>
    <div style={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundImage: `url(${props.isDarkMode ? WhiteBoardImg : BoardImg})`,
      backgroundSize: '100% 100%',
      zIndex: -1,
    }}/>
    <div style={{
      width: '320px', 
      height: '320px',
      display: 'flex', 
      flexWrap: 'wrap', 
      justifyContent: 'center',
      alignContent: 'center',
    }}>
      { [7, 8, 9, 4, 5, 6, 1, 2, 3].map((val) =>
          <Cell
            key={val}
            index={val}
            submitMove={props.submitMove}
            disabled={props.disabled}
            board={props.board}
            currentBoard={props.currentBoard}
            appendBoard={props.appendBoard}
            hoverIndex={props.hoverIndex}
            setHoverIndex={props.setHoverIndex}
            friendlyMoves={props.friendlyMoves}
            setFriendlyMoves={props.setFriendlyMoves}
            sendHoverMove={props.sendHoverMove}
            team={props.team}
            boardPrefix={props.boardPrefix || []}
            gameSettings={props.gameSettings}
            name={props.name}
            isDarkMode={props.isDarkMode}
          />
      ) }
    </div>
  </div>
}

function Button(props) {
  return <div
  onClick={props.disabled ? () => {} : props.onClick}
  style={{
    margin: '0.5em',
    paddingLeft: '1em',
    paddingRight: '1em',
    height: '2.2em',
    backgroundColor: props.isDarkMode ? "rgb(0 108 141)" : "rgb(78 173 255)",
    color: "white",
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: props.disabled ? 'default' : 'pointer',
    opacity: props.disabled ? 0.2 : 1,
    fontSize: '2em',
    boxShadow: '0px 0px 8px -1px rgba(0,0,0,0.55)',
    ...props.style
  }}
  className="tictactoe-usefont"
>
  {props.children}
</div>
}

function Cell(props) {
  const moveString = getMoveString(props.currentBoard, props.index.toString());

  let label = null;
  let disabled = props.disabled;
  let onClick = () => {
    props.setHoverIndex(props.index);
    props.sendHoverMove(moveString);
  }

  let isOnCurrentBoard = true;
  if (props.boardPrefix.length === props.currentBoard.length) {
    for (let i = 0; i < props.boardPrefix.length; i++) {
      isOnCurrentBoard &= (props.boardPrefix[i] === props.currentBoard[i]);
    }
  } else {
    isOnCurrentBoard = false;
  }
  disabled |= !isOnCurrentBoard;


  let zoomedBoard = props.board;
  for (let i = 0; i < props.boardPrefix.length; i++) {
    zoomedBoard = zoomedBoard[props.boardPrefix[i]];
  }
  const boardValue = zoomedBoard[props.index];

  if (boardValue) {
    if (typeof boardValue === 'object') {
      label = <div style={{width: '60px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div style={{ transform: `scale(${1/RECURSIVE_BOARD_SCALE})` }}>
          <Board {...props} boardPrefix={[...props.boardPrefix, props.index]} />
        </div>
      </div>
      onClick = () => {
        props.appendBoard(props.index);
        props.setHoverIndex(null);
      };
    } else if (typeof boardValue === 'string') {
      let imgSrc = null;
      if (boardValue === 'X') {
        imgSrc = XGuy;
      } else if (boardValue === 'O') {
        imgSrc = OGuy;
      } else {
        imgSrc = StarGuy;
      }
      label = <img width={70} height={70} alt={boardValue} src={imgSrc} />;
      disabled = true;
    }
  }

  let movesRowElements = [];
  if (!disabled) {
    if (props.hoverIndex === props.index) {
      label = <img width={60} height={60} alt={boardValue} src={props.team === 'X' ? XGuy : OGuy} style={{opacity: '0.5'}}/>
    }
  }

  if (isOnCurrentBoard) {
      movesRowElements = props.friendlyMoves
        .filter(move => (props.gameSettings.canSeeOtherMoves || move.key.startsWith(props.name)) && move.move.startsWith(moveString) && move.team === props.team)
        .sort((a, b) => (b.locked ? 1 : 0) - (a.locked ? 1 : 0))
        .map((move, i) => <FriendlyMoveDot key={i} team={props.team} locked={move.locked}/>);
  }

  const showBorder = !(disabled || typeof boardValue === 'object');

  return <div 
      onClick={disabled ? () => {} : onClick}
      style={{
        width: '65px', 
        height: '65px', 
        border: `1px ${showBorder ? 'dashed' : 'none'} ${props.isDarkMode ? 'white' : '#6eaff5'}`,
        borderRadius: '8px',
        margin: `${showBorder ? 15 : 16}px`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: disabled ? undefined : 'pointer',
        position: 'relative',
      }}>
    {movesRowElements.length > 0 && <div style={{
      position: 'absolute',
      top: '-2px',
      left: '-2px',
      display: 'flex',
      justifyContent: 'left',
      background: 'white',
      boxShadow: '0px 0px 8px -1px rgba(0,0,0,0.55)',
      borderRadius: '10px',
      padding: '3px',
      zIndex: 10,
    }}>
      {movesRowElements}
    </div> }
    {label}
  </div>
}

const FriendlyMoveDot = (props) => {
  const imgsrc = props.team === 'X'
    ? (props.locked ? XTeamIndicatorConfirmed : XTeamIndicator)
    : (props.locked ? OTeamIndicatorConfirmed : OTeamIndicator);

  return <img style = {{
    width: '10px',
    height: '10px',
    marginRight: '1px',
    marginLeft: '1px',
  }} src={imgsrc} alt=""/>
}

export default TicTacToe;
